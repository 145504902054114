div.table-wrapper {
    overflow-x: scroll;
}

tr.delim {
    background: #ddd;
}

tr.created {
    background: #BDEAD8;
}
tr.deleted {
    background: #E6B4B4;
}

td.changed {
    background: #CDEFFB;
}
td.deleted {
    background: #E6B4B4;
}
